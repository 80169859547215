/* eslint-disable */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { stripslashes, hideLoader } from "../Helpers/SettingHelper";

import { appId, languageCd } from "../Helpers/Config";
const lang = require('../Helpers/Language/lang'+'-'+languageCd);

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

var Parser = require('html-react-parser');

import { GET_REQUESTPAGEDATA} from '../../actions';

import PropTypes from 'prop-types';


class Pages extends Component {

    constructor(props) {
        super(props);
		this.state = {pagedata:[],pagedetail:''};
		const {page_slug}= this.props.match.params;
		this.props.getRequestpage(page_slug);
    }

     

    componentDidMount() {
		 $('.dvLoadrCls').show();
		 setTimeout(function(){ $('.test-popup-link').magnificPopup({type:'image'}); }, 2000);
    }
    
    componentWillReceiveProps(nextProps) {
		 if (nextProps.match.params.page_slug !== this.props.match.params.page_slug) {
			  this.props.getRequestpage(nextProps.match.params.page_slug);
		 }
		 
		 if(nextProps.pagedata !== this.state.pagedata) {
			 $('.dvLoadrCls').fadeOut(500);
			 var pagedataTxt = (Object.keys(nextProps.pagedata).length > 0) ? nextProps.pagedata[0].cmspage_description : '';
			 var pageDetails = (pagedataTxt !== '') ? Parser(pagedataTxt) : '';
			 this.setState({pagedata: nextProps.pagedata, pagedetail: pageDetails});
		 }
	}
	
	
	
	

    render() {

		return (<div className={"selected-lang-"+languageCd}>
		
				 <div className="common-top-div pagesList-main-div">
				    <div className="Pages">
					
					 {/* Header start */}
					 <Header />
					 {/* Header End */}
					 
					 <div className="cms-page">
						<div className="container-one cms-content">
						{this.state.pagedetail}
						</div>
					  </div>
					  
					</div>
				 </div>
				 
				 <Footer />		
				 <div id="dvLoading" className="dvLoadrCls"></div>
		       </div>);
    }
}



const mapStateToProps = (state) => {
	
	var pagedataRst = Array();
	if(Object.keys(state.pagedata).length > 0) {
		  if(state.pagedata[0].status === 'ok') {
			 pagedataRst = state.pagedata[0].result_set;
		  }
	}
	
	return {
		pagedata: pagedataRst
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRequestpage: (slug) => {
		dispatch({ type: GET_REQUESTPAGEDATA, slug});
		},
	}
}

Pages.propTypes = {
	history: PropTypes.shape({
	push: PropTypes.func.isRequired
	}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));

 
