import cookie from 'react-cookies';
import langImg from "../../common/images/eng_flg.png";

/* APP id */
export const appId       	= "12251625-040A-47C4-8980-B0578BCB94C7";
/*export const appId       	= "8ECF154D-2287-4476-99A3-121826D0137C";*/

/* Live */
export const apiUrl      	= "https://pluto.ninjaos.com/api/";
export const apiUrlV2    	= "https://pluto.ninjaos.com/apiv2/";
export const apiUrlPro   	= "https://pluto.ninjaos.com/ninjapro/";
export const apiUrlNotify 	= "https://pluto.ninjaos.com/Pushorder/";
export const timThumpUrl    = "https://pluto.ninjaos.com/timthumb.php?src=";
export const blogImageUrl   = "https://pluto.ninjaos.com/media/dev_team/blog/";
export const outletImageUrl = "https://pluto.ninjaos.com/media/dev_team/outlet/";
export const stripeImage    = "https://www.makisan.com/img/stripe_logo.png";

export const baseUrl      	= "https://www.makisan.com/";
/*export const baseUrl      	= "https://uat.makisan.com/";
export const baseUrl      	= "https://makisansg.jankosoftworks.com/";
export const baseUrl      	= "https://makisan.jankosoftworks.com/";*/

/* Dev */
/*export const apiUrl      	= "http://k2bdevteam.local/mercury/api/";
export const apiUrlV2    	= "http://k2bdevteam.local/mercury/apiv2/";
export const apiUrlPro   	= "http://k2bdevteam.local/mercury/ninjapro/";
export const apiUrlNotify   = "http://k2bdevteam.local/mercury/Pushorder/";
export const baseUrl      	= "http://makisan.jankosoftworks.com/";
export const timThumpUrl    = "http://k2bdevteam.local/mercury/timthumb.php?src=";
export const blogImageUrl   = "http://k2bdevteam.local/mercury/media/dev_team/blog/";
export const outletImageUrl = "http://k2bdevteam.local/mercury/media/dev_team/outlet/";
export const stripeImage    = "http://makisan.jankosoftworks.com/img/stripe_logo.png";*/

export const siteurlSingapore   = "https://www.makisan.com";
/*export const siteurlSingapore   = "https://uat.makisan.com";
export const siteurlSingapore   = "https://makisansg.jankosoftworks.com";*/
export const siteurlIndonesia   = "https://makisan.jankosoftworks.com";

export const defaultLang  		= "en";
export const defaultLangName  	= "English";
export const defaultLangFlag  	= langImg;
export const availLangFiles  	= ["en","ta","zh"];
export const defaultLangList  	= {en:"English"};

export const deliveryId  		= "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId    		= "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId    		= "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId    	= "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const productNoImg 		= "/img/product-noimg.jpg";
export const stripeCompany    	= "Maki-San";

export const fbAppId    		= "242854023732946";

export const stripeKey    		= "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail    	= "dev@jankosoft.com";
export const stripeReference    = "makisansgd";
/*export const stripeReference    = "makisanidr";*/
export const stripeDesc    		= "My Checkout";
export const stripeCurrency    	= (typeof (cookie.load("clientCurrencyName"))!="undefined"?cookie.load("clientCurrencyName"):"SGD");

export const languageCd = (typeof (cookie.load("language"))!="undefined"?cookie.load("language"):defaultLang);
export const languageNm = (typeof (cookie.load("language_name"))!="undefined"?cookie.load("language_name"):defaultLangName);
export const languageFg = (typeof (cookie.load("language_flag"))!="undefined"?cookie.load("language_flag"):defaultLangFlag);
export const clientCurrency = (typeof (cookie.load("clientCurrency"))!="undefined"?cookie.load("clientCurrency"):'$');
export const CountryTxt = (typeof (cookie.load("clientCountry"))!="undefined"?cookie.load("clientCountry"):"Singapore");
 
