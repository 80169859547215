var todayTimeSp = new Date();
var yearSp = todayTimeSp.getFullYear();

module.exports = {
   
    metadata: {
        home_title: 'வருக மகயி-சான்',
        home_desc: 'வருக மகயி-சான்',
		home_name_key: 'வருக மகயி-சான்',
    },
   
    /* Home page navigation items */
    navigation: {
        login: 'உள்நுழைவு',
        signup: 'பதிவுபெறு',
        register: 'பதிவுசெய்',
		myaccount: 'என் கணக்கு',
        logout:'வெளியேற',
		changepassword:'கடவுச்சொல்லை மாற்றவும்',
		searchboxlbl:'தேடல்...',
        searchnoresult:'தயாரிப்பு கிடைக்கவில்லை',
        orderNow:'ஆர்டர் இப்போது',
    },

	/* Face book  */
    fb: {
        fbURL: '',
        fbImage: '',
        fbTitle: '',
        fbDesc: '',
    },
	
    /* Twitter  */
    tw: {
        twURL: '',
        twTitile: '',
        twvia: '',
    },

    /* google Plus data   */
    gp: {
        gpURL: '',
    },

	countrypopup: {
		selected: 'சிங்கப்பூர்',
		/*selected: 'இந்தோனேஷிய',*/
        title: 'உங்கள் இருப்பிடத்தை தெரியப்படுத்துங்கள்',
        countrylable1: 'சிங்கப்பூர்',
		countrylable2: 'இந்தோனேஷிய'
    },
	
	availability: {
        delivery: 'டெலிவரி',
        takeaway: 'எடுத்து செல்',
		catering: 'கேட்டரிங்',
        reservation:'இடஒதுக்கீடு'
    },
	
	availabilitypopup: {
        title: 'ஆர்டர் இப்போது',
        subtitle: 'உங்கள் ஆர்டர் வகையை தேர்ந்தெடுக்கவும்',
        delivery: 'டெலிவரி',
        takeaway: 'எடுத்து செல்'
    },
	
	deliverypopup: {
        title: 'தெரியப்படுத்துவோம்',
        subtitle: 'உங்கள் டெலிவரி இருப்பிடம்',
        postalcodelbl: 'உங்கள் அஞ்சல் குறியீட்டை உள்ளிடவும்',
		newaddresslbl: 'புதிய முகவரியை உள்ளிடவும்',
		postalcodeerror: 'செல்லத்தக்க அஞ்சல் குறியீட்டை உள்ளிடுங்கள்.',
        backbtn: 'பின் செல்',
        continuebtn: 'தொடர'
    },
	
	deliveryerrorpopup: {
        title: 'வருந்துகிறேன்',
        subtitle: 'உங்கள் அஞ்சல் குறியீட்டை எங்களால் கண்டுபிடிக்க முடியவில்லை',
        postalcodelbl: 'உங்கள் அஞ்சல் குறியீட்டை உள்ளிடவும்',
		postalcodeerror: 'செல்லத்தக்க அஞ்சல் குறியீட்டை உள்ளிடுங்கள்.',
		addresserror_lbl1: 'இந்த நேரத்தில் எங்களால் வழங்க முடியாது!',
        addresserror_lbl2: 'தயவுசெய்து மீண்டும் வாருங்கள்.',
        change_address: 'முகவரியை மாற்றுக',
		change_outlet: 'அவுட்லெட் மாற்றுக',
        continuebtn: 'தொடர'
    },
	
	deliverysuccesspopup: {
        title: 'நன்றி',
        subtitle: 'எங்களால் வழங்க முடியும் !',
        deliveryaddresslbl: 'உங்கள் டெலிவரி முகவரி',
        pickupaddresslbl: 'உங்கள் பிக் அப் லொகேஷன்',
        deliverydateselectlbl: 'உங்கள் டெலிகாஸ்ட் தேதியை & நேரம் தேர்ந்தெடுக்கவும்',
        pickupdateselectlbl: 'உங்கள் பிக் அப் தேதியை & நேரம் தேர்ந்தெடுக்கவும்',
        continuebtn: 'தொடர'
    },
	
	takeawaypopup: {
        title: 'தயவுசெய்து தேர்ந்தெடுக்கவும்',
        subtitle: 'உங்கள் சுய சேகரிப்பு அவுட்லெட்',
		delvry_subtitle: 'உங்கள் டெலிவரி அவுட்லெட்',
        searchoutletlbl: 'தேடல் அவுட்லெட்',
        searchoutleterror: 'ஒரு அவுட்லெட்டைத் தேர்வு செய்யவும்.',
		searchoutleterror_map: 'தயவுசெய்து ஒரு கடையைத் தேடித் தேர்வுசெய்க.',
        innerlbl: 'அருகிலுள்ள விற்பனை நிலையங்கள்',
		nooutletfnd: 'அவுட்லெட் கிடைக்கவில்லை',
        continuebtn: 'தொடர'
    },
	
	warningpopup: {
        title: 'எச்சரிக்கை',
        subtitle: 'நீங்கள் உங்கள் வண்டியை வெளியே எடுக்க வேண்டும்.',
		subtitle_lang: 'வண்டியில் உள்ள உருப்படிகள் உட்பட தற்போதுள்ள அனைத்து மாற்றங்களும் அழிக்கப்படும்.',
        description: 'நீங்கள் தொடர விரும்புகிறீர்களா?',
        nobtn: 'இல்லை',
        yesbtn: 'ஆம்'
    },
	
	loginpopup: {
        title: 'கணக்கு இருக்கிறதா?',
        subtitle: 'நிர்வகிக்க உங்கள் விவரங்களுடன் உள்நுழையவும்.',
        fbnametext: 'பேஸ்புக் இல் உள்நுழையவும்',
		guestloginbtn: 'இல்லை நான் விருந்தாளியாக செக்அவுட் செய்ய விரும்புகிறேன்',
        separator: 'அல்லது',
        innerlbl: 'உடன் உள்நுழையவும்',
        emaillabel: 'மின்னஞ்சல் முகவரியை',
        passwordlabel: 'கடவுச்சொல்',
        isemptyerror: 'இந்த புலம் தேவை.',
        validemail: 'இது சரியான மின்னஞ்சல் அல்ல.',
        createaccountlink: 'கணக்கை உருவாக்கு',
        forgotpwdlink: 'கடவுச்சொல் மறந்துவிட்டதா',
        submitbtn: 'சமர்ப்பிக்க',
		successmsg: 'வெற்றிகரமாக உள்நுழைந்துள்ளார்!',
		errormsg: 'செல்லுபடியாகாத உள்நுழைவு நம்பிக்கைச்சான்றுகள்'
    },
	
	signuppopup: {
        title: 'மக்கிசான் வுக்கு புதுசு?',
        subtitle: 'உங்கள் மக்சான் கணக்கை உருவாக்குங்கள்.',
        fbnametext: 'பேஸ்புக் இல் உள்நுழையவும்',
		backbtn: 'பின்',
        separator: 'அல்லது',
        innerlbl: 'மூலம் கணக்கை உருவாக்கு',
        infolabel: 'உங்கள் தகவல்களை',
        firstnamelabel: 'முதல் பெயர்',
        lastnamelabel: 'கடைசிப் பெயர்',
        mobilenamelabel: 'கைபேசி எண்',
		loginlabel: 'உங்கள் உள்நுழைவு',
        emaillabel: 'மின்னஞ்சல் முகவரியை',
        passwordlabel: 'கடவுச்சொல்',
        confirmpwdlabel: 'கடவுச்சொல்லை உறுதிப்படுத்தவும்',
        isemptyerror: 'இந்த புலம் தேவை.',
        validemail: 'இது சரியான மின்னஞ்சல் அல்ல.',
		alreadymail: 'மின்னஞ்சல் ஏற்கனவே உள்ளது.',
        validemobile: 'செல்லத்தக்க மொபைல் எண்ணை உள்ளிடுங்கள்.',
		alreadymobile: 'மொபைல் எண் ஏற்கனவே உள்ளது.',
        validepassword: 'கடவுச்சொல் குறைந்தது 6 எழுத்துக்குறிகள் இருக்க வேண்டும்.',
        valideconfirmpwd: 'கடவுச்சொற்கள் பொருந்தவில்லை.',
        submitbtn: 'சமர்ப்பிக்க',
		successmsg: 'மெயில் அனுப்பப்பட்டுள்ளது.'
    },
	
	guestpopup: {
        title: 'மக்கிசான் வுக்கு புதுசு?',
        subtitle: 'உங்கள் மக்சான் கணக்கை உருவாக்குங்கள்.',
		backbtn: 'பின்',
		innerlbl: 'விருந்தினர் செக்அவுட் விவரங்கள்',
		mobilelabel: 'தொலைபேசி எண்',
		namelabel: 'பெயர்',
		emaillabel: 'மின்னஞ்சல்',
		isemptyerror: 'இந்த புலம் தேவை.',
        validemail: 'இது சரியான மின்னஞ்சல் அல்ல.',
		validemobile: 'செல்லத்தக்க மொபைல் எண்ணை உள்ளிடுங்கள்.',
        submitbtn: 'சமர்ப்பிக்க'
    },
	
	forgotpasswordpopup: {
        title: 'உங்கள் கடவுச்சொல் மறந்துவிட்டதா?',
        subtitle: 'உங்கள் கடவுச்சொல்லை இங்கே மீட்டமைக்கலாம்.',
		backbtn: 'உள்நுழைவதற்கு மீண்டும்',
		emaillabel: 'மின்னஞ்சல் முகவரியை',
		isemptyerror: 'இந்த புலம் தேவை.',
        validemail: 'இது சரியான மின்னஞ்சல் அல்ல.',
        submitbtn: 'மீட்டமைவு இணைப்பை பெறு'
    },
	
	resetpasswordpopup: {
        title: 'கடவுச்சொல் மீட்டமை',
		newpasswordlabel: 'புதிய கடவுச்சொல்',
		confirmpasswordlabel: 'கடவுச்சொல்லை உறுதிப்படுத்தவும்',
		newpassworderror: 'புதிய கடவுச்சொல் தேவைப்படுகிறது.',
        confirmpasserror: 'உறுதிசெய்தல் கடவுச்சொல் தேவைப்படுகிறது.',
        submitbtn: 'சமர்ப்பிக்க'
    },
	
	homebannerinfo: {
        title: 'ஒரிஜினல் டி. ஐ. ஒய். சுஷி அண்ட் சாலட் பிராண்ட்',
        subtitle: 'இப்போது சிங்கப்பூரிலும் இந்தோனேசியாவிலும்! அதிக நாடுகள் மிக விரைவில்!',
        orderNow:'ஆர்டர் இப்போது',
    },
	
	product: {
        ordernowbtn:'ஆர்டர் இப்போது',
        soldoutbtn:'விற்று',
        addtocartbtn:'கூடையில் சேர்க்கிறது',
        donebtn:'முடிந்தது',
		specialremark_placehldr:'உங்கள் சிறப்பு குறிப்பை பிரிவில் உள்ளீடு செய்யலாம்...',
        characters_remaining:'மீதமுள்ள கேரக்டர்கள்',
		share_url:'URL ஐ பகிரவும்',
		addtocart_success:'வெற்றியுடன்! உருப்படி உங்கள் கார்ட்டில் சேர்க்கப்பட்டது.',
		invalidproduct_error:'வருந்துகிறேன்!. செல்லுபடியாகாத தயாரிப்பு சேர்க்கை.'
    },
	
	cartlist: {
        title: 'உங்கள் ஆர்டர் விவரம்',
		subtitle: 'உங்கள் உருப்படிகளை',
        orderhandl: 'ஆர்டர் கையாளும் முறை',
        deliverylocation:'டெலிவரி இருப்பிடம்',
        pickuplocation:'பிக் அப் லொகேஷன்',
		deliverydate:'டெலிவரி தேதி',
        pickupdate:'பிஅப் தேதி',
		deliverytime:'டெலிவரி நேரம்',
        pickuptime:'பிக் அப் டைம்',
        cartclear:'வண்டியை அழி',
        subtotalLbl:'மொத்தம்',
        deliveryLbl:'டெலிவரி',
        additional_deliveryLbl:'கூடுதல் டெலிவரி',
        promo_code:'புரோமோ கோட்',
        gstLbl:'ஜி எஸ் டி',
        totalLbl:'மொத்தம்',
        checkout:'செக்அவுட்'
    },
	
	
	checkout: {
        title: 'செக்அவுட்',
		subtitle: 'உங்கள் ஆர்டர் விவரங்களை சரிபார்த்து உங்கள் கட்டணம் செலுத்தும் விருப்பங்களைத் தேர்ந்தெடுங்கள்',
		subtitle_addon: 'இந்த உருப்படிகளைச் சேர்க்க விரும்புகிறீர்களா?',
		locationtime: 'உங்கள் இருப்பிடம் & நேரம்',
		pickup_location_detile: 'சுய சேகரிப்பு விவரங்கள்',
		pickup_type_header: 'நீங்கள் அதை எப்படி விரும்புகிறீர்கள்',
		pickup_type_one: 'இங்கே உள்ளது',
		pickup_type_two: 'போவதற்கு',
		deliverylocation: 'டெலிவரி இருப்பிடம்',
		address_placeholder: 'பிளாக் 37, ஜலன் ரூமா கூசுகி',
		postal_code: 'அஞ்சல் குறியீடு',
		unit_no1: 'அலகு எண் 01',
		unit_no2: 'அலகு எண் 02',
		change_delivery_address: 'டெலிவரி முகவரியை மாற்றவும்',
		billing_address_lbl: 'பில்லிங் முகவரி',
		same_as_address: 'டெலிவரி முகவரியைப் போலவே',
		pickuplocation: 'பிக் அப் லொகேஷன்',
		change_pickup_location: 'பிக் அப் இருப்பிடத்தை மாற்றவும்',
		order_date_time: 'ஆர்டர் தேதி & நேரம்',
		special_instruction_lbl: 'சிறப்பு அறிவுறுத்தல்',
		order_details: 'உங்கள் ஆர்டர் விவரம்',
		order_items: 'உங்கள் உருப்படிகளை',
		clear_cart: 'ஆர்டர் அழி',
		redeem_title: 'ஈடு பெறு',
		redeem_subtitle: 'உங்கள் புள்ளிகளை இங்கே ஈடு செய்',
		redeem_chk_title: 'புள்ளிகளை ஈடு செய்',
		redeem_chk_btn: 'எனது புள்ளிகளை சரிபார்',
		reward_pointlink_text1: 'உங்களிடம்',
		reward_pointlink_text2: 'புள்ளிகள்',
		reward_pointlink_text3: 'உள்ளது',
		reward_placeholder1: 'நீங்கள் மீட்டுக்கொள்ளலாம் ',
		reward_placeholder2: ' புள்ளிகள்',
		rewardpromo_apply: 'இப்போது பயன்படுத்து',
		rewardpromo_remove: 'நுண்படி',
		reward_point_success: 'பரிசுகள் புள்ளிகள் வெற்றிகரமாக பயன்படுத்தப்பட்டது',
		reward_point_error: 'வருந்துகிறேன்! பரிசுகள் புள்ளிகளை பிரயோகிக்கவில்லை',
		promo_title: 'புரோமோ கோட்',
		promo_subtitle: 'உங்கள் புரோமோ குறியீட்டை இங்கே பயன்படுத்து',
		promo_pointlink_text2: 'புரோமோ',
		promo_lable: 'உங்கள் புரோமோ குறியீட்டை இங்கே சேர்க்கவும்',
		promo_code_success: 'புரோமோ வெற்றிகரமாக செயல்படுத்தபட்டது',
		promo_code_error: 'வருந்துகிறேன்! புரோமோ குறியீட்டைப் பிரயோகிக்கவில்லை',
		payment_method_title: 'உங்கள் பணம் செலுத்தும் முறையைத் தேர்ந்தெடுங்கள்',
		payment_method_cash: 'டெலிவரி பணம்',
		payment_method_promotion: 'உயர்த்துதல்',
		place_order: 'இடம் ஆர்டர்',
		order_reward_ern1: 'பெறுவீர்கள்',
		order_reward_ern2: 'பரிசு',
		order_reward_ern3: 'புள்ளிகள் இந்த ஆர்டர்',
		online_pay_title: 'இடையறா விழிப்பு!',
		online_pay_subtitle: 'ஆச்சரியப்படும் விதமாக! நீங்கள் எல்லாம் செட்',
		online_pay_action: 'பணம் செலுத்தத் தொடரவும்',
		processing_popup_title: 'உங்கள் ஆர்டரை நாங்கள் செயலாக்கிக்கிறோம்',
		processing_text: 'செயலாக்க',
		processing_card_info: 'அட்டை தகவலை செல்லுபடியாக்குதல்.',
		processing_order_placing: 'உங்கள் ஆர்டரை இப்போது வைப்பது.',
		processing_order_completing: 'உங்கள் ஆன்லைன் கட்டணச்செலவை நிறைவுசெய்கிறது.',
		order_outletdata_error: 'வருந்துகிறேன்!. உங்கள் ஆர்டர் அவுட்லெட் விவரம் காலியாக உள்ளது.',
		order_zonedata_error: 'வருந்துகிறேன்!. உங்கள் ஆர்டர் மண்டல விவரம் காலியாக உள்ளது.',
		cart_empty_error: 'வருந்துகிறேன்!. உங்கள் வண்டி காலியாக இருக்கிறது.',
		mobileno_empty_error: 'தயவுசெய்து உங்கள் மொபைல் எண் புதுப்பிக்கவும்.',
		order_datetime_error: 'தயவு செய்து ஆர்டர் தேதி மற்றும் நேரத்தை தேர்ந்தெடுக்கவும்.',
		order_billingaddres_error: 'தயவுசெய்து இயல்புநிலை பில்லிங் முகவரியை உள்ளிடவும்.',
		order_unitno_error: 'தயவு செய்து யூனிட் எண்ணை உள்ளிடவும்.',
		order_captureAmt_error: 'உங்கள் ஆர்டர் வெற்றியடையவில்லை.',
		order_paymentFail_error1: 'பிழை குறியீடு: 1001 Oops! ஏதோ தவறாகி விட்டது! தயவுசெய்து மீண்டும் முயற்சிக்கவும்.',
		order_paymentFail_error2: 'பிழை குறியீடு: 1002 Oops! சேவையகத்துடன் இணைக்க இயலவில்லை. தயவுசெய்து மீண்டும் முயற்சிக்கவும்.',
		order_paymentFail_error3: 'பிழைக் குறியீடு: 1003 ஊப்ஸ்! ஏதோ தவறாகி விட்டது! தயவுசெய்து மீண்டும் முயற்சிக்கவும்.',
		order_paymentFail_error4: 'பிழை குறியீடு: 1004 Oops! உங்கள் ஆர்டரை செயலாக்க இயலவில்லை. தயவுசெய்து மீண்டும் முயற்சிக்கவும்.',
    },
	
	rewardotppopup: {
		title: 'உங்கள் OTPஐ உள்ளிடவும்',
		otpresend_link1: 'பெறவில்லையா?',
		otpresend_link2: 'OTPஐ மீண்டும் அனுப்பு',
		otpresend_link3: 'மறுபடியும்',
		submit_btn: 'தொடர்',
        otp_send_success: 'சரிபார்ப்பு OTP உங்கள் பதிவு மொபைல் எண்ணுக்கு அனுப்பப்பட்டிருந்தது.',
        otp_send_error: 'வருந்துகிறேன்! சிறிது நேரம் கழித்து மீண்டும் முயற்சிக்கவும்',
        otp_verified_success: 'OTP வெற்றிகரமாக சரிபார்க்கப்பட்டது.',
        otp_verified_error: 'வருந்துகிறேன்! உங்கள் உள்ளிடப்பட்ட செல்லுபடியாகாத OTP. தயவுசெய்து மீண்டும் முயற்சிக்கவும்.'
    },
	
	thankyoupage: {
		title: 'நன்றி',
		subtitle: 'உங்கள் ஆர்டர் வெற்றிகரமாக வைக்கப்பட்டுள்ளது',
		order_no: 'ஆர்டர் எண்',
		order_placed: 'ஆர்டர்',
		order_payby: 'மூலம் செலுத்த',
        chkorder: 'ஆர்டர் நிலையை சரிபார்',
        order_invalid: 'செல்லுபடியாகாத ஆர்டர் விவரம்.'
    },
	
	myaccount: {
		title: 'என் கணக்கு',
		subtitle: 'எனது கணக்கு அறை உங்கள் சமீபத்திய செயல்பாடுகளை பார்க்க அனுமதிக்கிறது, உங்கள் வெகுமதி புள்ளிகளை சரிபார்க்கவும் மற்றும் கணக்குத் தகவலைப் புதுப்பிக்கவும்.',
		account_link: 'கணக்கு விவரங்கள்',
		order_link: 'சமயப்பணித் தரங்கள்',
		reward_link: 'வெகுமதிகள்',
        promotion_link: 'புரோமோ',
        account_plchrd: 'என் கணக்கு',
        order_plchrd: 'என் கட்டளைகள்',
        reward_plchrd: 'எனது வெகுமதிகள்',
        promotion_plchrd: 'என் புரோமோ'
    },
	
	myprofile: {
		title: 'கணக்குத் தகவல்',
		subtitle: 'எனது கணக்கு அறை உங்கள் சமீபத்திய செயல்பாடுகளை பார்க்க அனுமதிக்கிறது, உங்கள் வெகுமதி புள்ளிகளை சரிபார்க்கவும் மற்றும் கணக்குத் தகவலைப் புதுப்பிக்கவும்.',
		joinedon: 'இணைந்த தேதி',
		earned_points: 'ஈட்டிய மொத்த புள்ளிகள்',
		points_expiring: ' புள்ளிகள் காலாவதியாகும் 30 நாட்களில்',
		change_password: 'கடவுச்சொல்லை மாற்றவும்',
        logout_lnk: 'வெளியேற',
        hello: 'ஹலோ',
        account_info: 'உங்கள் கணக்குத் தகவல்',
		firstname: 'பெயர்',
        lastname: 'கடைசிப் பெயர்',
        nickname: 'விரும்பிய பெயர்',
        mobile: 'கைபேசி எண்',
        email: 'மின்னஞ்சல் முகவரியை',
        birthday: 'பிறந்தநாள்',
        gender_lbl1: 'ஆண்',
        gender_lbl2: 'பெண்',
        gender_lbl3: 'தனிப்படக் குறிக்கப்படாத',
        address: 'முகவரி',
        postal_code: 'அஞ்சல் குறியீடு',
        address_line1: 'முகவரி வரி 1',
        unit_num1: 'அலகு எண் 1',
        unit_num2: 'அலகு எண் 2',
        update_btn: 'புதுப்பித்தல்',
        other_address: 'பிற முகவரி'
    },
	
	changepasswdpopup: {
        title: 'கடவுச்சொல்லை மாற்றவும்',
		current_pwd: 'நடப்பு கடவுச்சொல்',
		new_pwd: 'புதிய கடவுச்சொல்',
		reenter_pwd: 'புதிய கடவுச்சொல்லை மீண்டும் உள்ளிடவும்',
		submit_btn: 'சமர்ப்பிக்க',
		success_msg: 'கடவுச்சொல் வெற்றிகரமாக மாற்றப்பட்டது!'
    },
	
	billingaddrpopup: {
        title: 'பில்லிங் முகவரி',
		postal_code: 'அஞ்சல் குறியீடு',
		postal_code_error: 'இது சரியான அஞ்சல் குறியீடு அல்ல.',
		address_ln: 'முகவரி வரி',
		unit_no1: 'அலகு எண் 1',
		unit_no2: 'அலகு எண் 2',
		submit_btn: 'சமர்ப்பிக்க',
		success_msg: 'அழகு! உங்கள் பில்லிங் முகவரி வெற்றிகரமாக சேர்க்கப்பட்டது.',
		deleted_msg: 'முகவரி வெற்றிகரமாக நீக்கப்பட்டது!'
    },
	
	myorders: {
        current_orders: 'தற்போதைய கட்டளைகள்',
		past_orders: 'கடந்த கால கட்டளைகள்',
		loat_more: 'மேலும் ஏற்றுக',
		order_no: 'ஆர்டர் எண்',
		view_recipt: 'ரசீதை காண்',
		print_invoice: 'அச்சு விலைப்பட்டியல்',
		no_records: 'பதிவேடுகள் எதுவும் காணப்படவில்லை',
		delivery_frm: 'இருந்து டெலிவரி',
		delivery_to: 'டெலிவரி',
		email_receipt: 'மின்னஞ்சல் ரசீது',
		order_items: 'ஆர்டர் உருப்படிகள்',
		delivery_charges: 'டெலிவரி கட்டணங்கள்'
    },
	
	myrewards: {
        title: 'உங்கள் தற்போதைய வெகுமதி புள்ளிகள்',
		points: 'புள்ளிகள்',
		rewards_earn: 'ஈட்டிய வெகுமதிகள்',
		rewards_redeem: 'வெகுமதிகள் மறுநிகர்நிலை',
		time:'காலம்',
		discount:'கழிவு'
    },
	
	mypromo: {
        title: 'உங்கள்',
        promotions: 'புரோமோ குறியீடு',
		placeholder: 'உங்கள் புரோமோ குறியீட்டை இங்கே சேர்க்கவும்',
		apply_btn: 'இடு',
		promotions_used: 'பயன்படுத்தப்பட்ட புரோமோ குறியீடு',
		promotion_name:'புரோமோ பெயர்',
		valid_till:'வரை செல்லுபடியாகும்',
		view_now:'இப்போது காண்',
		redeem:'விட்டுக்கொள்',
		empty_promo:'தயவுசெய்து உங்கள் புரோமோ குறியீட்டை உள்ளிடவும்.',
		cart_valid:'புரோமோ குறியீட்டை விண்ணப்பிக்க ஆர்டர் அளவு போதாது.',
		promo_code_success: 'புரோமோ குறியீட்டை வெற்றிகரமாக செயல்படுத்தபட்டது',
		promo_code_error: 'வருந்துகிறேன்! புரோமோ குறியீட்டைப் பிரயோகிக்கவில்லை'
    },
	
	footer: {
        copyright: 'பதிப்புரிமை '+yearSp+' மகி சான். அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை. வடிவமைப்பு ஜான்கோஸால்'
    },

    common: {
		countrylable:'சிங்கப்பூர்',
		/*countrylable:'இந்தோனேஷிய',*/
		orderhandledtext:'குழுவினர் உங்களை பார்த்து இருப்பார்கள்',
        minutestext:'நிமிடங்கள்',
		alertsuccesstitle:'வெற்றி',
        alerterrortitle:'பிழை',
		alertbutton:'சரி',
		account_activation_success:'உங்கள் கணக்கு வெற்றிகரமாக செயற்படுத்தப்பட்டுள்ளது. தொடர்வதற்கு தயவுசெய்து உள்நுழையவும்.',
		account_activation_error:'உங்கள் செயற்படுத்தல் இணைப்பு காலாவதியாகிவிட்டது. உங்கள் நிர்வாகி தொடர்பு கொள்ளவும்.',
		addtocart_success:'அருமையான தேர்வு! உருப்படி உங்கள் கார்ட்டில் சேர்க்கப்பட்டது.',
		addtocart_error:'வருந்துகிறேன்! தயாரிப்புகள் உங்கள் கார்ட்டில் சேர்க்க முடியாது.',
		addons_header:'துணைப்-சேர்ப்பான்கள்'
    },
    
};
