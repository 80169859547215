var todayTimeSp = new Date();
var yearSp = todayTimeSp.getFullYear();

module.exports = {
   
    metadata: {
        home_title: '欢迎来到马基山',
        home_desc: '欢迎来到马基山',
		home_name_key: '欢迎来到马基山',
    },
   
    /* Home page navigation items */
    navigation: {
        login: '登录',
        signup: '登记',
        register: '注册',
		myaccount: '我的账户',
        logout:'登出',
		changepassword:'更改密码',
		searchboxlbl:'搜索。。。',
        searchnoresult:'未找到产品',
        orderNow:'立即订购',
    },

	/* Face book  */
    fb: {
        fbURL: '',
        fbImage: '',
        fbTitle: '',
        fbDesc: '',
    },
	
    /* Twitter  */
    tw: {
        twURL: '',
        twTitile: '',
        twvia: '',
    },

    /* google Plus data   */
    gp: {
        gpURL: '',
    },

	countrypopup: {
		selected: '新加坡',
		/*selected: '印尼',*/
        title: '让我们知道您的位置',
        countrylable1: '新加坡',
		countrylable2: '印尼'
    },
	
	availability: {
        delivery: '送货',
        takeaway: '外卖',
		catering: '餐饮',
        reservation:'预订'
    },
	
	availabilitypopup: {
        title: '立即订购',
        subtitle: '选择您的订单类型',
        delivery: '送货',
        takeaway: '外卖'
    },
	
	deliverypopup: {
        title: '让我们知道',
        subtitle: '您的送货地点',
        postalcodelbl: '输入邮政编码',
		newaddresslbl: '输入新地址',
		postalcodeerror: '请输入有效的邮政编码。',
        backbtn: '回去',
        continuebtn: '继续'
    },
	
	deliveryerrorpopup: {
        title: '对不起',
        subtitle: '我们找不到您的邮政编码',
        postalcodelbl: '输入邮政编码',
		postalcodeerror: '请输入有效的邮政编码。',
        continuebtn: '继续'
    },
	
	deliverysuccesspopup: {
        title: '谢谢',
        subtitle: '我们可以交付！',
        deliveryaddresslbl: '您的送货地址',
        pickupaddresslbl: '您的取件位置是',
        deliverydateselectlbl: '选择您的约会日期和时间',
        pickupdateselectlbl: '选择您的取件日期和时间',
        continuebtn: '继续'
    },
	
	takeawaypopup: {
        title: '请选择',
        subtitle: '您的自我收藏插座',
		delvry_subtitle: '您的送货出口',
        searchoutletlbl: '搜索出口',
        searchoutleterror: '请选择一个插座。',
        innerlbl: '附近的出口',
		nooutletfnd: '未找到出口',
        continuebtn: '继续'
    },
	
	warningpopup: {
        title: '警告',
        subtitle: '通过切换，您将清除您的购物车。',
        description: '是否要继续？',
        nobtn: '不',
        yesbtn: '是的'
    },
	
	loginpopup: {
        title: '有帐户吗？',
        subtitle: '登录，并记录要管理的详细信息。',
        fbnametext: '使用 Facebook 登录',
		guestloginbtn: '否 我想以客人身份结帐',
        separator: '或',
        innerlbl: '使用登录',
        emaillabel: '邮箱地址',
        passwordlabel: '密码',
        isemptyerror: '这是必填栏。',
        validemail: '这不是有效的电子邮件。',
		createaccountlink: '创建帐户',
        forgotpwdlink: '忘记密码',
        submitbtn: '提交',
		successmsg: '已成功登录！',
		errormsg: '无效的登录凭据'
    },
	
	signuppopup: {
        title: '马基桑的新人？',
        subtitle: '创建您的马基桑帐户。',
        fbnametext: '使用 Facebook 登录',
		backbtn: '返回',
        separator: '或',
        innerlbl: '使用',
        infolabel: '您的信息',
        firstnamelabel: '名',
        lastnamelabel: '姓',
        mobilenamelabel: '手机号码',
		loginlabel: '您的登录',
        emaillabel: '邮箱地址',
        passwordlabel: '密码',
        confirmpwdlabel: '确认密码',
        isemptyerror: '这是必填栏。',
        validemail: '这不是有效的电子邮件。',
		alreadymail: '电子邮件已存在。',
        validemobile: '请输入有效的手机号码。',
		alreadymobile: '手机号码已存在。',
        validepassword: '密码必须至少为 6 个字符。',
        valideconfirmpwd: '密码不匹配。',
        submitbtn: '提交',
		successmsg: '邮件已发送。'
    },
	
	guestpopup: {
        title: '马基桑的新人？',
        subtitle: '创建您的马基桑帐户。',
		backbtn: '返回',
		innerlbl: '客人退房详情',
		mobilelabel: '电话号码',
		namelabel: '姓名',
		emaillabel: '电子邮箱',
		isemptyerror: '这是必填栏。',
        validemail: '这不是有效的电子邮件。',
		validemobile: '请输入有效的手机号码。',
        submitbtn: '提交'
    },
	
	forgotpasswordpopup: {
        title: '忘记密码？',
        subtitle: '您可以在此处重置密码。',
		backbtn: '返回登录',
		emaillabel: '邮箱地址',
		isemptyerror: '这是必填栏。',
        validemail: '这不是有效的电子邮件。',
        submitbtn: '获取重置链接'
    },
	
	resetpasswordpopup: {
        title: '重置密码',
		newpasswordlabel: '新密码',
		confirmpasswordlabel: '确认密码',
		newpassworderror: '需要新密码。',
        confirmpasserror: '确认密码是必需的。',
        submitbtn: '提交'
    },
	
	homebannerinfo: {
        title: '原装D.I.Y.寿司和沙拉品牌',
        subtitle: '现在在新加坡和印度尼西亚！更多国家很快！',
        orderNow:'立即订购',
    },
	
	product: {
        ordernowbtn:'立即订购',
        soldoutbtn:'已售完',
        addtocartbtn:'添加到购物车',
        donebtn:'完成',
		specialremark_placehldr:'您可以在部分中输入您的特殊注释...',
        characters_remaining:'剩余字符',
		share_url:'共享 URL',
		addtocart_success:'成功！添加到购物车的项目。',
		invalidproduct_error:'对不起！。无效的产品组合。'
    },
	
	cartlist: {
        title: '您的订单详情',
		subtitle: '您的项目',
        orderhandl: '订单处理者',
        deliverylocation:'交货地点',
        pickuplocation:'取件位置',
		deliverydate:'交货日期',
        pickupdate:'取件日期',
		deliverytime:'交货时间',
        pickuptime:'取件时间',
        cartclear:'清除购物车',
        subtotalLbl:'次 全 切除',
        deliveryLbl:'交货',
        additional_deliveryLbl:'额外交付',
        promo_code:'促销代码',
        gstLbl:'GST',
        totalLbl:'总',
        checkout:'结帐'
    },
	
	
	checkout: {
        title: '结帐',
		subtitle: '检查您的订单详细信息并选择您的付款选项',
		subtitle_addon: '您要添加这些项目吗？',
		locationtime: '您的位置和时间',
		pickup_location_detile: '自我收集详细信息',
		pickup_type_header: '你想如何拥有它',
		pickup_type_one: '在这里',
		pickup_type_two: '去',
		deliverylocation: '交货地点',
		address_placeholder: '第37座，贾兰·鲁马·廷吉',
		unit_no1: '单元号 01',
		unit_no2: '单元号 02',
		change_delivery_address: '更改送货地址',
		billing_address_lbl: '帐单地址',
		same_as_address: '与送货地址相同',
		pickuplocation: '取件位置',
		change_pickup_location: '更改取件位置',
		order_date_time: '订单日期 时间',
		special_instruction_lbl: '特别说明',
		order_details: '您的订单详细信息',
		order_items: '您的项目',
		clear_cart: '清除购物车',
		redeem_title: '获得兑换',
		redeem_subtitle: '在此处兑换积分',
		redeem_chk_title: '兑换积分',
		redeem_chk_btn: '检查我的积分',
		reward_pointlink_text1: '你有',
		reward_pointlink_text2: '点',
		reward_pointlink_text3: '可用',
		reward_placeholder1: '您可以兑换 ',
		reward_placeholder2: ' 点',
		rewardpromo_apply: '立即申请',
		rewardpromo_remove: '移除',
		reward_point_success: '成功应用奖励积分',
		reward_point_error: '对不起！未应用奖励积分',
		promo_title: '促销代码',
		promo_subtitle: '在此处应用促销代码',
		promo_pointlink_text2: '促销',
		promo_lable: '在此处添加促销代码',
		promo_code_success: '已成功应用促销',
		promo_code_error: '对不起！未应用促销代码',
		order_reward_ern1: '你会得到',
		order_reward_ern2: '奖励',
		order_reward_ern3: '具有此顺序的点',
		payment_method_title: '选择您的付款方式',
		payment_method_cash: '货到付款',
		payment_method_promotion: '升职',
		place_order: '下订单',
		online_pay_title: '警报！',
		online_pay_subtitle: '棒！您都已设置',
		online_pay_action: '继续付款',
		processing_popup_title: '我们正在处理您的订单',
		processing_text: '处理中',
		processing_card_info: '验证卡信息。',
		processing_order_placing: '立即下订单。',
		processing_order_completing: '完成您的在线付款。',
		order_outletdata_error: '对不起！。您的订单出口详细信息为空。',
		order_zonedata_error: '对不起！。您的订单区域详细信息为空。',
		cart_empty_error: '对不起！。您的购物车是空的。',
		mobileno_empty_error: '请更新您的手机号。',
		order_datetime_error: '请选择订单日期和时间。',
		order_billingaddres_error: '请输入默认帐单地址。',
		order_unitno_error: '请输入单位号。',
		order_captureAmt_error: '您的订单未成功。',
		order_paymentFail_error1: '错误代码： 1001 哎呀！出问题了！请重试。',
		order_paymentFail_error2: '错误代码： 1002 哎呀！无法连接到服务器。请重试。',
		order_paymentFail_error3: '错误代码：1003 哎呀！出问题了！请重试。',
		order_paymentFail_error4: '错误代码： 1004 哎呀！无法处理您的订单。请重试。',
    },
	
	rewardotppopup: {
		title: '输入您的 OTP',
		otpresend_link1: '没有收到？',
		otpresend_link2: '重新发送 OTP',
		otpresend_link3: '再次',
		submit_btn: '继续',
        otp_send_success: '验证 OTP 已发送您的注册手机号码。',
        otp_send_error: '对不起！一段时间后再试一次',
        otp_verified_success: 'OTP 已成功验证。',
        otp_verified_error: '对不起！您输入的无效 OTP。请重试。'
    },
	
	thankyoupage: {
		title: '谢谢你',
		subtitle: '您的订单已成功下单',
		order_no: '订单号',
		order_placed: '订单放置在',
		order_payby: '付款人',
        chkorder: '检查订单状态',
        order_invalid: '无效的订单详细信息。'
    },
	
	myaccount: {
		title: '我的账户',
		subtitle: '"我的帐户"仪表板允许您查看您最近的活动、检查奖励积分和更新帐户信息。',
		account_link: '帐户详细信息',
		order_link: '订单',
		reward_link: '奖励',
        promotion_link: '促销',
        account_plchrd: '我的账户',
        order_plchrd: '我的订单',
        reward_plchrd: '我的奖励',
        promotion_plchrd: '我的促销'
    },
	
	myprofile: {
		title: '帐户信息',
		subtitle: '我的帐户"仪表板允许您查看您最近的活动、检查奖励积分和更新帐户信息。',
		joinedon: '加入日期',
		earned_points: '总积分',
		points_expiring: ' 30 天后过期的积分',
		change_password: '更改密码',
        logout_lnk: '登出',
        hello: '你好',
        account_info: '您的帐户信息',
		firstname: '姓名',
        lastname: '姓',
        nickname: '首选名称',
        mobile: '手机号码',
        email: '邮箱地址',
        birthday: '生日',
        gender_lbl1: '男性',
        gender_lbl2: '女性',
        gender_lbl3: '未指定',
        address: '地址',
        postal_code: '邮政编码',
        address_line1: '地址行 1',
        unit_num1: '单元号 1',
        unit_num2: '单元 2',
        update_btn: '更新',
        other_address: '其他地址'
    },
	
	changepasswdpopup: {
        title: '更改密码',
		current_pwd: '当前密码',
		new_pwd: '新密码',
		reenter_pwd: '重新输入新密码',
		submit_btn: '提交',
		success_msg: '密码更改成功！'
    },
	
	billingaddrpopup: {
        title: '帐单地址',
		postal_code: '邮政编码',
		postal_code_error: '这不是有效的邮政编码。',
		address_ln: '地址行',
		unit_no1: '单元号 1',
		unit_no2: '单元号 2',
		submit_btn: '提交',
		success_msg: '好！您的帐单地址已成功添加。',
		deleted_msg: '已成功删除地址！'
    },
	
	myorders: {
        current_orders: '当前订单',
		past_orders: '过去订单',
		loat_more: '加载更多',
		order_no: '订单号',
		view_recipt: '查看收据',
		print_invoice: '打印发票',
		no_records: '未找到记录',
		delivery_frm: '交货自',
		delivery_to: '交付至',
		email_receipt: '电子邮件收据',
		order_items: '订购项目',
		delivery_charges: '送货费'
    },
	
	myrewards: {
        title: '您当前的奖励积分',
		points: '点',
		rewards_earn: '获得的奖励',
		rewards_redeem: '已兑换的奖励',
		time:'时间',
		discount:'折扣'
    },
	
	mypromo: {
        title: '兑换您的',
        promotions: '促销',
		placeholder: '在此处添加促销代码',
		apply_btn: '应用',
		promotions_used: '使用的促销',
		promotion_name:'促销名称',
		valid_till:'有效期直到',
		view_now:'立即查看',
		redeem:'赎回',
		empty_promo:'请输入您的促销代码。',
		cart_valid:'购物车数量不足以应用促销。',
		promo_code_success: '已成功应用促销',
		promo_code_error: '对不起！未应用促销代码'
    },
	
	footer: {
        copyright: '版权所有 '+yearSp+' 马基山. 保留所有权利。设计由扬科软'
    },
	
    common: {
		countrylable:'新加坡',
		/*countrylable:'印尼',*/
		orderhandledtext:'船员将看到你在',
        minutestext:'分钟',
		alertsuccesstitle:'成功',
        alerterrortitle:'错误',
		alertbutton:'好的。',
		account_activation_success:'您的帐户已成功激活。请登录以继续。',
		account_activation_error:'您的激活链接已过期。请与您的管理员联系。',
		addtocart_success:'伟大的选择！添加到购物车的项目。',
		addtocart_error:'对不起！产品无法添加购物车。',
		addons_header:'附加组件'

    },
    
};
