/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Slider from "react-slick";

import { stripslashes, showPriceValue, callImage } from "../Helpers/SettingHelper";
import { GET_MENU_NAVIGATION } from '../../actions';

class MenuNavigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: []
    }
	
  }

  navigateMenuList(){
       
	    let navigateMenuArr = this.props.navigateMenu;
	    var selectedNav = this.props.productState.selectedNavigation;
		if(Object.keys(navigateMenuArr).length > 0) {
			const mainMenu = navigateMenuArr.map((loaddata, index) => {
				if (loaddata.menu_type == "main") {
					return (<div key={index+1} className={(selectedNav === loaddata.pro_cate_slug)?"bakery_slide active":"bakery_slide"}>
								<p> 
								<Link to={"/products/category/" + loaddata.pro_cate_slug} title={loaddata.menu_custom_title} >
								{ stripslashes(loaddata.menu_custom_title) }
								</Link>
								</p>                                                    
						  </div>);
				} else if (loaddata.menu_type == "sub") {
					return (<div key={index+1} className={(selectedNav === loaddata.pro_subcate_slug)?"bakery_slide active":"bakery_slide"}>
								<p> 
								<Link to={"/products/subcategory/" + loaddata.pro_subcate_slug} title={loaddata.menu_custom_title} >
								{ stripslashes(loaddata.menu_custom_title) }
								</Link>
								</p>                                                    
						  </div>);
				}
			});
			
			return mainMenu;
			
		} else {
			
			return '';
		}

   }
  
  componentDidMount() {
	
  }

  render() {
	let navigateMenuArr = this.props.navigateMenu;
	var navInxd = this.props.productState.catNavIndex;
		navInxd = (navInxd != '')?navInxd:0;
		
   if(Object.keys(navigateMenuArr).length > 0) {
	   
	var infiniteSts = (Object.keys(navigateMenuArr).length > 3) ? true : false;   
     
	var navSettings = {
			infinite: infiniteSts,
			slidesToShow: 4,
			slidesToScroll: 1,
			initialSlide: navInxd,

			responsive: [{
				breakpoint: 1191,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 3,
					infinite: infiniteSts

				}
			},
			{
				breakpoint: 850,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 680,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
			]
		};  
    }
    return (<div className="menu-section_full">
				  <div className="bakery">
					  <div className="container-one">
							<div className="bakery_row">
								<div className="bakery_slider">
								 { (Object.keys(navigateMenuArr).length > 0) ? <Slider { ...navSettings} >
									{this.navigateMenuList()}
								</Slider> : "" }
								</div>
							</div>
						</div>
					</div>
				</div>)
  }

}

export default (MenuNavigation);